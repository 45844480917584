import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';
import { ROWS_PER_PAGE_DEFAULT } from 'shared/constants/rowsPerPage';

const EMPTY_INSTRUCTOR = { instructor: '', start: '', end: '', id: 'new' };

export const getLessonPlans = createAsyncThunk(
  'lessonPlans/getLessonPlans',
  async ({ gradeId, currentPage }, { getState }) => {
    const {
      lessonPlans: { pagination },
      auth: { currentUser },
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get('lesson-plan', {
      params: {
        gradeId,
        academicYearId: selectedYear.id,
        instructorId: currentUser.role === 'INSTRUCTOR' ? currentUser.id : null,
        currentPage: currentPage ?? pagination.currentPage,
      },
    });

    return response.data;
  }
);

export const getLessonPlansToEvaluations = createAsyncThunk(
  'lessonPlans/getLessonPlansToEvaluations',
  async ({ gradeId, academicYearId, currentPage }, { getState }) => {
    const {
      lessonPlans: { pagination },
    } = getState();

    const response = await api.get('lesson-plan', {
      params: {
        gradeId,
        academicYearId,
        currentPage: currentPage ?? pagination.currentPage,
      },
    });

    return response.data;
  }
);

export const getLessonPlanById = createAsyncThunk(
  'lessonPlans/getLessonPlanById',
  async (lessonPlanId) => {
    try {
      const { data } = await api.get(`lesson-plan/${lessonPlanId}`);
      return data;
    } catch (error) {
      console.error('lesson Plans API error: ', error.message);
      return [];
    }
  }
);

export const createLessonPlan = createAsyncThunk(
  'lessonPlans/createLessonPlan',
  async (lessonPlan) => {
    try {
      const response = await api.post('lesson-plan', {
        ...lessonPlan,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const syncLessonPlan = createAsyncThunk(
  'lessonPlans/syncLessonPlan',
  async (instructors, { getState }) => {
    const {
      lessonPlans: { selectedLessonPlan },
    } = getState();

    try {
      const response = await api.put(
        `lesson-plan/${selectedLessonPlan.data.id}/instructor`,
        { lessonPlanInstructors: instructors }
      );
      const { data } = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const finishLessonsPLans = createAsyncThunk(
  'lessonPlans/finishLessonsPLans',
  async (gradeId) => {
    try {
      const response = await api.post(`lesson-plan/lesson/all`, {
        gradeId,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const curriculumComponentSelect = createAsyncThunk(
  'lessonPlans/curriculumComponentSelect',
  async ({ currentPage, academicYearId }, { dispatch, getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get('curriculum-component', {
      params: {
        currentPage: currentPage ?? null,
        academicYearId: selectedYear.id,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(
        curriculumComponentSelect({
          currentPage: data.currentPage + 1,
          academicYearId,
        })
      );
    }

    return data;
  }
);

const lessonPlansAdapter = createEntityAdapter({});

export const {
  selectAll: selectLessonPlans,
  selectEntities: selectLessonPlansEntities,
  selectById: selectLessonPlanById,
} = lessonPlansAdapter.getSelectors((state) => state.lessonPlans);

const lessonPlansSlice = createSlice({
  name: 'lessonPlans',
  initialState: lessonPlansAdapter.getInitialState({
    isLoading: false,
    selectOptions: {
      year: '',
      grade: '',
    },
    selectedLessonPlan: {
      data: '',
      type: 'new',
    },
    confirmFinishModal: false,
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      totalPages: 1,
      totalRowCount: 0,
    },
    lessonPlanInstructors: [],
    lessonPlanModal: {
      show: false,
      type: 'new',
      data: null,
    },
    lessonPlansToEvaluations: [],
    curriculumCompontensSelect: [],
  }),
  reducers: {
    editSelectedLessonPlan: (state, { payload }) => {
      state.selectedLessonPlan = {
        data: payload,
      };
      state.lessonPlanInstructors = payload.instructors;
    },
    openConfirmModal: (state) => {
      state.confirmFinishModal = true;
    },
    closeConfirmModal: (state) => {
      state.confirmFinishModal = false;
    },
    handleChange: (state, { payload }) => {
      state.selectOptions = { ...state.selectOptions, ...payload };
    },
    addInstructorForm: (state) => {
      state.lessonPlanInstructors = [
        ...state.lessonPlanInstructors,
        EMPTY_INSTRUCTOR,
      ];
    },
    setSelectedLessonPlan: (state, { payload }) => {
      state.selectedLessonPlan.data = payload;
      state.lessonPlanInstructors = payload.instructors;
    },

    openLessonPlanModal: (state) => {
      state.lessonPlanModal = { show: true, type: 'new', data: null };
    },
    closeLessonPlanModal: (state) => {
      state.lessonPlanModal = { data: null, show: false, type: 'new' };
    },
    clearLessonPlans: (state) => {
      lessonPlansAdapter.setAll(state, []);
    },
  },
  extraReducers: {
    [getLessonPlans.pending]: (state) => {
      state.isLoading = true;
    },
    [getLessonPlans.fulfilled]: (state, { payload }) => {
      lessonPlansAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
      state.isLoading = false;
    },
    [getLessonPlans.rejected]: (state) => {
      state.isLoading = false;
    },

    [createLessonPlan.fulfilled]: (state, action) => {
      const { payload } = action;
      if (payload.success) {
        lessonPlansAdapter.addOne(state, payload.data);
        state.selectedLessonPlan = {
          data: payload.data,
          type: 'edit',
        };
      }
      return state;
    },

    [getLessonPlanById.pending]: (state) => {
      state.isLoading = true;
    },

    [getLessonPlanById.fulfilled]: (state, { payload }) => {
      state.selectedLessonPlan.data = payload;
      state.lessonPlanInstructors = payload.instructors;
      state.isLoading = false;
    },
    [getLessonPlanById.rejected]: (state) => {
      state.isLoading = false;
    },

    [syncLessonPlan.fulfilled]: (state, action) => {
      const { payload } = action;

      const instructors = payload.data.map((instructor) => {
        return {
          user: instructor.user,
          start: instructor.start,
          end: instructor.end,
        };
      });

      if (payload.success) {
        state.lessonPlanInstructors = instructors;
      }
      return state;
    },

    [getLessonPlansToEvaluations.fulfilled]: (state, { payload }) => {
      state.lessonPlansToEvaluations = payload.data;
    },

    [curriculumComponentSelect.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        state.curriculumCompontensSelect = payload.data;
      } else {
        state.curriculumCompontensSelect = [
          ...state.curriculumCompontensSelect,
          ...payload.data,
        ];
      }
    },
  },
});

export const {
  selectValue,
  editSelectedLessonPlan,
  openConfirmModal,
  closeConfirmModal,
  handleChange,
  addInstructorForm,
  setSelectedLessonPlan,
  openLessonPlanModal,
  closeLessonPlanModal,
  clearLessonPlans,
} = lessonPlansSlice.actions;

export default lessonPlansSlice.reducer;
